// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll(
  'video[playsinline]'
) as NodeListOf<HTMLVideoElement>

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

const ContactFormWrapper = document.getElementById('ContactFormWrapper')
if (ContactFormWrapper && ContactFormWrapper !== null) {
  import('./ContactForm/index.ts')
    .then((module) => {
      module.default(ContactFormWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const logoBtn = document.querySelector('.logo-btn') as HTMLButtonElement
const logoWrapper = document.querySelector('.logo-wrapper') as HTMLDivElement

if (logoBtn && logoWrapper) {
  logoBtn.addEventListener('click', () => {
    logoWrapper.style.height = 'auto'
    logoBtn.style.display = 'none'
  })
}

const logoCount = document.querySelector('[data-block="logo-grid"]')?.getAttribute('data-logo-count')
const button = document.getElementById('logo-btn') as HTMLButtonElement

function updateButtonVisibility() {
  const screenWidth = window.innerWidth

  if (screenWidth >= 1455 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 18) {
    button.style.display = 'block'
  } else if (screenWidth >= 1137 && screenWidth < 1454 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 15) {
    button.style.display = 'block'
  } else if (screenWidth >= 947 && screenWidth < 1136 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 12) {
    button.style.display = 'block'
  } else if (screenWidth >= 709 && screenWidth < 946 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 9) {
    button.style.display = 'block'
  } else if (screenWidth >= 640 && screenWidth < 708 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 6) {
    button.style.display = 'block'
  } else if (screenWidth >= 517 && screenWidth < 639 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 9) {
    button.style.display = 'block'
  } else if (screenWidth < 516 && logoCount !== null && logoCount !== undefined && Number(logoCount) > 6) {
    button.style.display = 'block'
  } else {
    button.style.display = 'none'
  }
}

updateButtonVisibility()
window.addEventListener('resize', updateButtonVisibility)
